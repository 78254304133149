import React, { FC } from "react";
import PrimaryLayout from "../components/layout/primary/primary";
import { graphql } from "gatsby";
import BlogPostPreview from "../components/blog/blog-post-preview";
import SEO from "../components/seo";
import { Box } from "theme-ui";
import FlexWrapper from "../components/container/flex-wrapper";
import ContentContainer from "../components/container/content-container";
import ColorDivider from "../components/color-divider/color-divider";
import PhotoSlider from "../components/photo-slider/photo-slider";
//import Instagram from "../components/instagram/instagram";
import styles from "../components/index.style";

export const query = graphql`
  query {
    photoseries: allPrismicBlogContentModel(
      sort: { fields: last_publication_date, order: DESC }
      limit: 1
      filter: {
        data: { story_content_type: { id: { eq: "YBqpzREAACAAQSMp" } } }
      }
    ) {
      edges {
        node {
          uid
          data {
            post_title {
              text
            }
            excerpt
            story_content_type {
              document {
                ... on PrismicStoryType {
                  data {
                    name
                  }
                }
              }
            }
            featured_image {
              fluid(maxWidth: 575) {
                ...GatsbyImgixFluid_noBase64
              }
            }
            body {
              ... on PrismicBlogContentModelDataBodyImageGallery {
                items {
                  gallery_image {
                    fluid(maxWidth: 475) {
                      ...GatsbyImgixFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    editorial: allPrismicBlogContentModel(
      sort: { fields: last_publication_date, order: DESC }
      limit: 1
      filter: {
        data: { story_content_type: { id: { eq: "X48WyBAAACEAEodi" } } }
      }
    ) {
      edges {
        node {
          data {
            post_title {
              text
            }
            excerpt
            story_content_type {
              document {
                ... on PrismicStoryType {
                  data {
                    name
                  }
                }
              }
            }
            featured_image {
              fluid(maxWidth: 575) {
                ...GatsbyImgixFluid_noBase64
              }
            }
          }
          uid
        }
      }
    }
    cg_story: allPrismicBlogContentModel(
      sort: { fields: last_publication_date, order: DESC }
      limit: 1
      filter: {
        data: { story_content_type: { id: { eq: "X48W-RAAACMAEohM" } } }
      }
    ) {
      edges {
        node {
          data {
            post_title {
              text
            }
            excerpt
            story_content_type {
              document {
                ... on PrismicStoryType {
                  data {
                    name
                  }
                }
              }
            }
            featured_image {
              fluid(maxWidth: 575) {
                ...GatsbyImgixFluid_noBase64
              }
            }
          }
          uid
        }
      }
    }
    cg_shopping: allPrismicBlogContentModel(
      sort: { fields: last_publication_date, order: DESC }
      limit: 1
      filter: {
        data: { story_content_type: { id: { eq: "X9LekhAAACQATvWt" } } }
      }
    ) {
      edges {
        node {
          data {
            post_title {
              text
            }
            excerpt
            story_content_type {
              document {
                ... on PrismicStoryType {
                  data {
                    name
                  }
                }
              }
            }
            featured_image {
              fluid(maxWidth: 575) {
                ...GatsbyImgixFluid_noBase64
              }
            }
          }
          uid
        }
      }
    }
    cg_community: allPrismicBlogContentModel(
      sort: { fields: last_publication_date, order: DESC }
      limit: 1
      filter: {
        data: { story_content_type: { id: { eq: "Ygp3_xEAACMAZYru" } } }
      }
    ) {
      edges {
        node {
          data {
            post_title {
              text
            }
            excerpt
            story_content_type {
              document {
                ... on PrismicStoryType {
                  data {
                    name
                  }
                }
              }
            }
            featured_image {
              fluid(maxWidth: 575) {
                ...GatsbyImgixFluid_noBase64
              }
            }
          }
          uid
        }
      }
    }
    cg_initiative: allPrismicBlogContentModel(
      sort: { fields: last_publication_date, order: DESC }
      limit: 1
      filter: {
        data: { story_content_type: { id: { eq: "X48XEBAAACAAEoi7" } } }
      }
    ) {
      edges {
        node {
          data {
            post_title {
              text
            }
            excerpt
            story_content_type {
              document {
                ... on PrismicStoryType {
                  data {
                    name
                  }
                }
              }
            }
            featured_image {
              fluid(maxWidth: 575) {
                ...GatsbyImgixFluid_noBase64
              }
            }
          }
          uid
        }
      }
    }
  }
`;

interface BlogPageProps {
  data: any;
}

const BlogPage: FC<BlogPageProps> = ({ data }) => {
  const storiesLg = data.photoseries.edges[0].node;
  const smTwo = data.cg_shopping.edges[0].node;
  const smOne = data.editorial.edges[0].node;
  const smThree = data.cg_story.edges[0].node;
  const smFour = data.cg_community.edges[0].node;
  const smFive = data.cg_initiative.edges[0].node;
  const storiesSmOne = Array.from([smOne, smTwo]);
  const storiesSmTwo = Array.from([smThree, smFour]);
  const photoArray = storiesLg.data.body[0].items;
  const category = storiesLg.data.story_content_type?.document.data?.name;
  const featuredImage = storiesLg.data.featured_image;
  const metaDefault = "Articles by Common Ground marketplace";

  return (
    <>
      <PrimaryLayout>
        <SEO title="Stories" description={metaDefault} />
        <ContentContainer width="100%">
          <PhotoSlider
            images={photoArray}
            link={storiesLg?.uid}
            category={category}
            featured={featuredImage}
          />
          <ColorDivider color="cgFushia" />
          <FlexWrapper padded>
            {storiesSmOne?.map((post) => {
              return (
                <BlogPostPreview
                  content={post}
                  previewType="stories"
                  key={post.uid}
                />
              );
            })}
          </FlexWrapper>
          <ColorDivider color="cgLime" />
          <FlexWrapper padded>
            {storiesSmTwo?.map((post) => {
              return (
                <BlogPostPreview
                  content={post}
                  previewType="stories"
                  key={post.uid}
                />
              );
            })}
          </FlexWrapper>
          {smFive && (
            <FlexWrapper padded>
              <BlogPostPreview
                content={smFive}
                previewType="stories"
                key={smFive.uid}
              />
            </FlexWrapper>
          )}
          <Box
            sx={{
              height: "30px",
              "@media screen and (min-width: 591px)": {
                height: "90px",
              },
            }}
          />
          {/*<Box sx={{
            "@media screen and (max-width: 590px)": {
              display: "none",
            }}}>
            <ColorDivider color="cgMustard" />
          </Box>
          */}
          <div>{/*<Instagram />*/}</div>
        </ContentContainer>
      </PrimaryLayout>
    </>
  );
};
export default BlogPage;
